/* eslint-disable prefer-regex-literals */
import _ from 'lodash';
import Cookie from 'js-cookie';
import moment from 'moment';
import {
  startOfMonth,
  endOfMonth,
  format,
  startOfYear,
  endOfYear,
} from 'date-fns';

export const checkPermissions = (validPermissions) => {
  const basePermission = JSON.parse(Cookie.get('_permissions'));
  if (validPermissions.length > 0) {
    const newPermissions = basePermission
      ? basePermission.filter((item) => validPermissions.indexOf(item) > -1)
      : [];
    return validPermissions.length === 0 || newPermissions.length > 0;
  }
  return false;
};

export const getFirstRoute = (options) => {
  const target = (options || []).find(item => item.isPermitted);
  const url = _.get(target, 'url');
  if (_.isEmpty(url) || _.isNil(url)) {
    return '/no-access';
  }
  return url;
};
export const randomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min) + min);

export const removeEmptyFromObject = (obj) =>
  _.omitBy(obj, (item) => (item === '' || _.isNil(item)));

export const formatCurrency = (number) => `₱ ${new Intl.NumberFormat('en', { minimumFractionDigits: 2 }).format(number)}`

export const formatNumberComma = (number) => {
  // allows negative values
  const count = (s1, letter) => s1.split(letter).length - 1;
  if (_.isNil(number)) return 0;
  if ((Number.isNaN(number) && !_.isEmpty(number)) || (count(`${number}`, '.') > 1)) return number;

  const initValue = number.toString();
  const wholeValue = initValue.split('.')[0];
  const decimalValue = initValue.split('.')[1];
  const processedWholeValue = wholeValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // return if whole number or with decimal
  const newValue = count(`${initValue}`, '.') > 0 ? `${processedWholeValue}.${decimalValue}` : processedWholeValue;
  return newValue;
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const startOfTheMonth = startOfMonth(new Date());
export const endOfTheMonth = endOfMonth(new Date());

export const startOfTheYear = startOfYear(new Date());
export const endOfTheYear = endOfYear(new Date());

export const formatDateString = (date) =>
  date ? format(new Date(date), 'yyyy-MM-dd') : '';

export const formatDateDisplay = (date) =>
  date ? format(new Date(date), 'MMM dd, yyyy') : '';

export const formatDateTimeDisplay = (date) =>
  date ? format(new Date(date), 'MMM dd, yyyy hh:mm aa') : '';

export const formatSelectOption = (
  list = [],
  value = 'id',
  label = 'name',
  isToUpperCase = true
) => {
  const newData = [];
  list.forEach((item) => {
    newData.push({
      value: _.get(item, `${value}`),
      label: isToUpperCase
        ? _.get(item, `${label}`).toUpperCase()
        : _.get(item, `${label}`),
      data: { ...item },
      id: _.get(item, `${value}`),
    });
  });
  return newData;
};

export const objectToUpperCase = (params) => {
  const newObjt = {};
  Object.keys(params).map((item) => {
    const x = '';
    newObjt[item] =
      params[item] && typeof params[item] === 'string'
        ? `${params[item].toUpperCase()}`
        : params[item];
    return x;
  });
  return newObjt;
};

export const stringToUpperCase = (string) => {
  if (!_.isNil(string) && typeof string === 'string') {
    return string.toUpperCase();
  }
  return '';
};

export const jsUcFirst = (string) =>
  string
    .toLowerCase()
    .replace(new RegExp('(?:\\b|_)([a-z])', 'g'), (e) => e.toUpperCase());

export const jsUcOnlyFirst = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const loadIncludedToList = (items, included) =>
  items.map((x) => {
    const rowIncluded = {};
    _.forOwn(x?.relationships, (v, k) => {
      rowIncluded[k] = Array.isArray(v.data)
        ? v.data.map(
            (z) =>
              included.find(
                (y) => y.type === _.get(z, 'type') && y.id === _.get(z, 'id')
              ) || {}
          )
        : included.find(
            (y) =>
              y.type === _.get(v, 'data.type') && y.id === _.get(v, 'data.id')
          ) || {};
    });
    return { ...x, included: rowIncluded };
  });

export const flatIncludedToItem = (x, included) => {
  const rowIncluded = {};
  _.forOwn(x?.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === _.get(z, 'type') && y.id === _.get(z, 'id')
            ) || {}
        )
      : included.find(
          (y) =>
            y.type === _.get(v, 'data.type') && y.id === _.get(v, 'data.id')
        ) || {};
  });
  return { ...x, included: rowIncluded };
};

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => ({
      ...obj,
      [item[key]]: item,
    }), initialValue);
};

export const replaceText = (value, replace) => {
  if (value) {
    return value.replaceAll(replace, '');
  }
  return '';
};

export const sortDateArray = (list, key, order) => {
  // order = asc/desc
  if ((list || []).length < 1) return [];
  const newData = _.orderBy(list, (item) => moment(item[key]).format('MM-DD-YYYY h:mm:ss a'), [order]);

  return newData;
};

export const convertObjToOptions = (data) => {
  let options = [];
  _.forOwn(data, (value, label) => {
    const newItem = {
      id: value,
      value,
      label: _.startCase(label.replaceAll('_', ' ').toLowerCase()),
    };
    options = [...options, newItem];
  });
  return _.sortBy(options, ['label']);;
}

export const renderTransactionStatus = (status) => {
  if (_.isNil(status)) return status;
  const formattedStatus = status.replaceAll('_', ' ').toLowerCase();
  return _.startCase(formattedStatus);
};


export const renderColoredTransactionStatus = (status) => {
  const statusButton = {
    pending: 'bg-yellow-600 text-white',
    delivered: 'bg-green-700 text-white',
    departed: 'bg-gray-300 text-gray-900',
    default: 'bg-gray-300 text-gray-900',
  }

  if (_.isNil(status)) return status;
  const formattedStatus = statusButton[status] || statusButton.default;
  return formattedStatus;
};

export const removeObjectToList = (list = [], id = '', accesor = null) => {
  let newList = [];
  newList = list.filter((item) => `${accesor ? item[accesor] : item}` !== `${id}`);
  return newList;
};

export const getDateDifferenceInMinutes = (date1, date2) => {
  let diff =(date2.getTime() - date1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};
